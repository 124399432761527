/* SVGIcon.css */

.svg-icon-container {
  display: inline-block;
  transition: color 0.3s ease;
  color: white;
}

.svg-icon-container:hover {
  color: #b3f2d1;
}

/* Travel.css */

.caption {
  bottom: 0;
  left: 0;
  width: 100%;
  color: white;
  padding-top: -5px;
  text-align: center;
  font-size: 0.8rem;
}

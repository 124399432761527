/* Footer.css */

.footer {
  text-align: center;
  padding: 5px;
  position: relative;
  bottom: 0px;
  width: 100%;
  font-size: 10px;
  padding-top: 60px;
}

footer a {
  text-decoration: none;
  margin-left: 10px; /* Adjust the margin for spacing between elements */
  margin-bottom: 0px;
  padding-bottom: 0px;
}

footer p {
  color: white;
  margin-left: 10px; /* Adjust the margin for spacing between elements */
  margin-top: 4px;
  padding-top: 0px;
  font-size: 12px;
}

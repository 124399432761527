/* Musings.css */

.article-preview {
  text-decoration: none;
  display: block;
  /* color: white; */
}

.article-preview:hover {
  color: #b3f2d1;
}

.article-preview h2 {
  font-size: 1.8rem;
  margin-bottom: 0;
}

.article-preview small {
  color: white;
}

.article-preview a {
  color: white;
}

.highlight-on-hover {
  transition:
    transform 0.2s ease-in-out 0s,
    opacity 0.2s linear 0.4s;
  position: relative;

  &:hover {
    scale: 1.01;
  }
}

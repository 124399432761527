/* random.css */

.random-container {
  flex-direction: column; /* Ensure the list items are arranged vertically */
}

.random-container ul {
  display: grid;
  justify-content: left;
}

/* Apply this CSS to your list container (ul or ol) */
.list-container {
  list-style-type: disc; /* You can use 'disc', 'circle', 'square', etc. */
  width: 100%;
}

/* Optional: Add margin or padding to the list items for spacing */
.list-container li a {
  margin-bottom: 8px; /* Adjust as needed */
}

.content {
  flex: 1;
  /* padding-bottom: 250px; Adjust the padding to match the height of the Mario game */
}

.mario-container {
  position: relative;
  bottom: -150px; /* This will position the Mario game at the bottom */
}

/* Bio.css */

img {
  width: 280px;
  height: auto; /* Maintain the image's aspect ratio */
  border-radius: 20px;
}

.img-container {
  max-width: 100%; /* Ensure the image doesn't exceed the container width */
  margin: auto; /* Center the image horizontally */
  text-align: center;
  /* margin-top: 20px; */
}

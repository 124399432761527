/* Mario.css */

.big {
  image-rendering: pixelated;
  position: relative;
  top: -50px;
}

/* Animación CSS de caminar */
@keyframes walk {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 120px 0;
  }
}

/* Clase para activar la animación (mientras se pulsa tecla) */
.caminar {
  animation: walk 0.25s steps(2) infinite forwards;
}

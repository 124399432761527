.App {
  text-align: center;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    animation: fadeIn 1s ease-in-out;
  }
}

.App-header {
  background-color: #69482d;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.centered-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  font-size: 1rem;
}

.left-container {
  display: flex;
  align-items: flex-start; /* Align items to the start of the cross-axis (top) */
  justify-content: flex-start; /* Justify items to the start of the main axis (left) */
  text-align: left;
  flex: 1;
  margin-top: 80px;
  max-width: 80vw;
  font-size: 1rem;
  width: 100%;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-align: left; /* Align headings to the left */
}

h1 {
  margin-bottom: 20px;
}

body a {
  color: #b3f2d1;
}

body a:hover {
  color: #6effb4;
}
